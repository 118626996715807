


















































import { Vue, Component } from "vue-property-decorator";
import { organisationModule } from "@/store/modules/OrganisationModule";
import { OrganisationUnit } from "../models/OrganisationUnit";
import { OrganisationUnitTreeNode } from "@/models/OrganisationUnitTreeNode";
import adminService from "@/services/AdminService";
import "vuetify/dist/vuetify.min.css";

@Component
export default class OrganisationUnits extends Vue {
  public items: OrganisationUnitTreeNode[] = [];
  public query = "";

  public generateItemLabel = (item: OrganisationUnitTreeNode) => {
    let label = item.name;

    if (item.costCenters.length) {
      label += " (" + item.costCenters.map(x => x.toString()).join(", ") + ")";
    }

    return label;
  }

  public getItems() {
    if (this.query.length < 1) {
      return this.items;
    }
    
    const getMatches = (nodes: OrganisationUnitTreeNode[]) => {
      const matches: OrganisationUnitTreeNode[] = [];

      nodes.forEach(x => {
        const node = {...x};
        let hasChildMatches = false;

        if (node.children.length) {
          const childMatches = getMatches(node.children);

          if (childMatches.length) {
            node.children = childMatches;

            matches.push(node);

            hasChildMatches = true;
          }
        }
        
        if (!hasChildMatches) {
          let query = this.query.replaceAll(" ", "").toLowerCase();

          let nameMatch = node.name.replaceAll(" ", "").toLowerCase()
            .includes(query);
          let costCenterMatch = node.costCenters.find(x => x.toString().includes(query));
          
          if (nameMatch || costCenterMatch) {
            node.children = [];

            matches.push(node);
          }
        }
      })
      
      return matches;
    }

    return getMatches(this.items);
  }

  private createDataTree(orgUnits: OrganisationUnit[]): OrganisationUnitTreeNode[] {
    const hashTable = Object.create(null);
    
    orgUnits.forEach(
      (aData) => (hashTable[aData.id] = { ...aData, children: [] })
    );

    const dataTree = [];

    orgUnits.forEach((aData) => {
      if (aData.parentId)
        hashTable[aData.parentId].children.push(hashTable[aData.id]);
      else dataTree.push(hashTable[aData.id]);
    });

    return dataTree;
  }

  private async activated() {
    let organisationUnits = await adminService.getOrganisationUnits(organisationModule.organisationDetails.id);

    this.items = this.createDataTree(organisationUnits);
  }
}
